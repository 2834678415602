import React from "react";
import {
  Box,
  Button,
  Card,
  Typography,
} from "@material-ui/core";
import { useExperience } from "../contexts/experience";
import String from "../components/String";

export default function Replay({ spot }) {

  const { replay, setVisited } = useExperience();

  return (
    <Card style={{ textAlign: "center", borderRadius: 0, padding: 10 }}>
      <Box mt={3} mb={2}>
        <Typography color="textSecondary" variant="h5" mt={1}>
          {spot.label}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography color="textSecondary" variant="p" mt={1}>
          <String s="replay"/>
        </Typography>
      </Box>
      <Box mb={1}>
        <Button 
        color="secondary"
        variant="contained"
        onClick={replay}>
            <String s="replay_yes"/>
        </Button>
      </Box>
      <Box mb={1}>
        <Button variant="string" onClick={() => setVisited(undefined)}>
          <Typography color="textSecondary">
            <String s="replay_no"/>
          </Typography>
        </Button>
      </Box>
    </Card>
  );
}