import palette from "./palette";
import common from "./common.js";

// night theme
const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    background: {
      default: palette.dark_grey,
      rgb: palette.dark_grey_rgb,
      contrast: palette.white,
    },
    secondary: {
      main: palette.dark_grey,
    },
    text: {
      primary: palette.white,
      secondary: palette.dark_grey,
    },
  }
};

const image_dir = "./night";

const night = { styles, image_dir };

export default night;
