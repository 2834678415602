import React, {useState} from "react";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import AdminControls from './AdminControls';
import logoLight from "../../assets/logo_light.png";
import logoDark from "../../assets/logo_dark.png";
import { useTheme } from "../contexts/theme";

const ADMIN_PANEL_AFTER = 5; // clicks

export default function Header() {

  const [showAdmin, setShowAdmin] = useState(0);
  const { theme } = useTheme();

  const openAdminDialog = () => {
    setShowAdmin(prev => prev + 1);
    setTimeout(() => {
      setShowAdmin(prev => {
        return prev < ADMIN_PANEL_AFTER ? 0 : prev;
      })
    }, ADMIN_PANEL_AFTER * 1000);
  }

  return (
    <AppBar position="static">
      <Toolbar style={toolbar}>
        <Box onClick={openAdminDialog} height="7vh">
          {theme === "day" ? 
            <img alt="Atlantide" src={logoLight} height="100%" />
            : <img alt="Atlantide" src={logoDark} height="100%" />
          }
        </Box>
      </Toolbar>
      <AdminControls show={showAdmin >= ADMIN_PANEL_AFTER} onClose={() => setShowAdmin(0)}/>
    </AppBar>
  );
}

const toolbar = {
  justifyContent: "center",
  paddingTop: "10px",
  paddingBottom: "10px",
};
