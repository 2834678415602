import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack
} from "@material-ui/core";
import Layout from "../components/Layout";
import Routes from "../components/Routes";
import Logo from "../components/Logo";
import { useLanguage } from "../contexts/language";
import String from "../components/String";
import AppVersion from "../components/AppVersion";
import BackIcon from "@material-ui/icons/ArrowRightAlt";
import Image from "../components/Image";
import test_sound from "../../assets/audio/test_sound.mp3";
import geoConfig from "../../config/geo/index";
import ThemeSwitcher from "../components/ThemeSwitcher";

const gpsConfig = {
  enableHighAccuracy: geoConfig?.options?.enableHighAccuracy || true,
  maximumAge: geoConfig?.options?.maximumAge || 30000,
  timeout: geoConfig?.options?.timeout || 27000
}

const steps = [
  // { path: "/boarding/1", component: Language },
  { path: "/boarding/1", component: Headphones },
  { path: "/boarding/2", component: Audio },
  { path: "/boarding/3", component: Gps },
];

// boarding view
export default function Boarding() {
  
  const { language } = useLanguage();
  const views = boardingViews(steps, language);
  return (
    <Layout
      full
      textured
      top={
        <Box mt="8vh" textAlign="center">
          <Logo />
        </Box>
      }
      bottom={
        <Box p={3} textAlign="center">
          <PoweredBy />
        </Box>
      }
    >
      <AppVersion />
      <Routes views={views} />
    </Layout>
  );
}

// function Language(p) {
//   const { language, availableLanguages, setLanguage } = useLanguage();
//   return (
//     <BoardingStep
//       title={<String s="select_lang" />}
//       form={
//         <FormControl variant="outlined" fullWidth>
//           <Select
//             value={language}
//             onChange={(e) => setLanguage(e.target.value)}
//           >
//             {availableLanguages.map((l) => (
//               <MenuItem value={l} key={l}>
//                 <String s="lang" l={l} />
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       }
//       action={<String s="confirm" />}
//       themeSwitcher
//       {...p}
//     />
//   );
// }

function Headphones(p) {
  return (
    <BoardingStep
      title={<String s="headset" />}
      image={"headphones"}
      action={<String s="continue" />}
      {...p}
    />
  );
}

function Audio(p) {
  return (
    <>
      <BoardingStep
        pretitle={<String s="raise_volume" />}
        title={<String s="sound" />}
        image={"phone"}
        action={<String s="yes_continue" />}
        {...p}
      />
      <audio src={test_sound} autoPlay loop style={{ display: "none" }} />
    </>
  );
}

const getGpsQuality = (accuracy) => {
  // bypass gps accuracy check
  if (window.gpsAccuracyCheckForced) return 3

  // gps quality index 0: no position, 1: bad, 2: average, 3: good
  if (accuracy <= geoConfig.options.accuracy * 0.2) return 3
  if (accuracy <= geoConfig.options.accuracy) return 2
  if (accuracy) return 1
  return 0
}

function Gps(p) {

  const [gpsStatus, setGpsStatus] = useState({quality: 0, accuracy: undefined, error: undefined})
  const geoWatchSub = useRef()
  useEffect(() => {
    geoWatchSub.current = navigator.geolocation.watchPosition((position) => {
      console.log("position update", position)
      setGpsStatus({error: undefined, accuracy: position.coords.accuracy, quality: getGpsQuality(position?.coords?.accuracy)})
    },(error) => {
      console.error("geolocation error", error)
      if (error.code === 1) {
        setGpsStatus({error: error.message, accuracy: undefined, quality: 0})
      }
    },gpsConfig);
    return () => { geoWatchSub.current && navigator.geolocation.clearWatch(geoWatchSub.current) };
  }, [])

  // override gps controls
  const forceGpsAccuracyCheck = () => {
    setGpsStatus({error: undefined, accuracy: 10, quality: 3});
    window.gpsAccuracyCheckForced = true;
  }
  window.forceGpsAccuracyCheck = forceGpsAccuracyCheck;

  return (
    <>
      <BoardingStep
        pretitle={<String s="wait_location" />}
        title={<String s="gps" />}
        image={"gps"}
        action={
          gpsStatus.error ? undefined
          : gpsStatus.quality <= 1 ? <String s="wait" />
          : <String s="continue" />}
        disabled={gpsStatus.error || gpsStatus.quality <= 1}
        form={gpsStatus.error ? <>
            <Typography variant={"title"} mb={2}><String s="gps_error"/></Typography>
            <Typography style={{fontSize: "10px"}}>
              {gpsStatus.error}
            </Typography>
          </>
        : <>
          {gpsStatus.quality === 1 ? 
            <Typography color="red" variant="pretitle" mb={0}><String s="gps_quality_bad"/></Typography>
          : gpsStatus.quality === 2 ?
            <Typography color="#CAA53D" variant="pretitle" mb={0}><String s="gps_quality_average"/></Typography>
          : gpsStatus.quality === 3 ?
            <Typography color="green" variant="pretitle" mb={0}><String s="gps_quality_good" /></Typography>
          : null}
        </>}
        {...p}
      />
    </>
  );
}

const boardingViews = (steps, language) =>
  steps.map(({ component: Component, path }, i) => {
    const next = steps[i + 1] ? steps[i + 1].path :  `/tour?lang=${language}`;
    const back = steps[i - 1] ? steps[i - 1].path : null;
    return {
      path,
      render: (props) => (
        <Component
          steps={steps}
          step={i + 1}
          next={next}
          back={back}
          {...props}
        />
      ),
      exact: true,
    };
  });

function BoardingStep({
  title,
  pretitle,
  text,
  image,
  media,
  form,
  action,
  auxAction,
  auxActionClick,
  next,
  disabled,
  themeSwitcher,
  ...p
}) {
  return (
    <Layout justify="space-between">
      <Stack flexGrow={1} justifyContent="center" width="90%">
        <Stack textAlign="center" spacing={2} mb={"3vh"}>
          {pretitle && (
            <Typography variant={"pretitle"}>
              {pretitle}
            </Typography>
          )}
          <Typography variant={"title"}>
            {title}
          </Typography>
          {text && (
            <Typography variant={"text"}>
              {text}
            </Typography>
          )}
          {media}
          {(image || form) && <Box>
            {image && <Image i={image} style={{ height: "15vh" }} />}
            {form}
          </Box>}
          {action && <Box mt={3}>
            <Button
              variant="contained"
              component={Link}
              to={next}
              disabled={disabled}
              fullWidth
            >
              {action}
            </Button>
          </Box>}
        </Stack>
      </Stack>
      <Box mb={1}>
        <StepNav {...p} />
      </Box>
      <ThemeSwitcher invertColors/>
    </Layout>
  );
}

function StepNav({ step, steps, back }) {
  return (
    <Grid container alignItems="center" height="50px" width="40vw">
      <Grid item xs={3} align="center">
        {back && (
          <Link to={back}>
            <Typography fontSize={"4vw"} color="textPrimary">
              <BackIcon
                style={{ transform: "rotate(-180deg)", fontSize: "40px" }}
              />
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid item xs={6} align="center">
        <Typography fontSize={"6vw"} color="textPrimary">
          {step}/{steps.length}
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

function PoweredBy() {
  return <Typography color="textPrimary" variant="text">Powered by mezzoforte</Typography>;
}
