import React from "react";
import logo from "../../assets/logo.png";
import logo_light from "../../assets/logo_light.png";
import { useTheme } from "../contexts/theme";

export default function Logo() {
  const { theme } = useTheme();
  if (theme === "night") return <img alt="Atlantide" src={logo_light} style={{maxWidth: "30%"}} />
  return <img alt="Atlantide" src={logo} style={{maxWidth: "30%"}} />
}
