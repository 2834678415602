const it = {
  lang: "Italiano",
  select_lang: "Scegli la lingua",
  day: "Giorno",
  night: "Notte",
  confirm: "Conferma",
  continue: "Continua",
  yes_continue: "Si, continua",
  start: "Inizia",
  back: "Indietro",
  wait: "Attendi...",
  headset: "Indossa le cuffie in dotazione per sentire l'audio correttamente",
  raise_volume: "Aumenta il volume dello smartphone",
  sound: "Stai sentendo l'audio correttamente?",
  wait_location: "Verifica il segnale GPS",
  gps: "L'app sta cercando di capire dove ti trovi",
  gps_error: "C'è un problema con il GPS di questo telefono",
  gps_quality_bad: "SEGNALE SCARSO",
  gps_quality_average: "SEGNALE ACCETTABILE",
  gps_quality_good: "SEGNALE OTTIMO",
  cookies: "Esiste un giro salvato",
  cookies_action: "Vuoi riprendere il giro o iniziarne uno nuovo?",
  cookies_delete: "Inizia nuovo giro",
  cookies_maintain: "Riprendi il giro",
  unlocking:
    "Durante la visita, se devi usare l'app, sbloccala con questa sequenza",
  // unlock: 'Sblocca con il segno "L"',
  unlock: 'Confermare lo sblocco?',
  unlock_subtitle: 'Lo sblocco dello schermo è riservato agli admin',
  areyouready: "Sei pronto per iniziare la visita?",
  beforestart: "Prima di cominciare a camminare clicca su Inizia, così l'app sara gia attiva e potrai goderti l'esperienza sonora senza dover più interagire con l'app.",
  welcome: "BENVENUTO",
  map: "Mappa",
  code: "FAQ",
  insert_code:
    "Inserisci il numero corrispondente alle TAPPE DEL VIAGGIO che trovi sul depliant",
  faq: [
    {
      q: "Problemi con la connessione?",
      a: "Se hai problemi con la connessione prova a...",
    },
    { q: "Altra FAQ", a: "Se hai problemi con la connessione prova a..." },
  ],
  mobile_only: "Questa App è  progettata per dispositivi mobili",
  screen_locked: "Schermata bloccata",
  swipe_left: "Scorri verso sinistra per sbloccare",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_test: "Test audio",
  admin_reset_hard: "Ricomincia visita",
  end_title: "La visita è terminata",
  end_message: "Grazie di aver fatto con noi questa esperienza sonora aumentata. Se vuoi ricominciare la visita, premi il pulsante qui sotto.",
  end_reset_hard: "Ricomincia visita",
  search_gps: "Attesa segnale GPS",
  replay: "Vuoi riascoltare questo contenuto?",
  replay_yes: "Riascolta",
  replay_no: "Chiudi"
};

export default it;
