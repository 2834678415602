import React from "react";
import { Stack, Container, Hidden, Typography, Box } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { useTheme } from "../contexts/theme";
import texture_svg from "../../assets/svg/texture.svg";
import Image from "./Image";
import String from "./String";
import Logo from "./Logo";

export default function Layout({
  top,
  bottom,
  full,
  direction,
  justify,
  align,
  textured,
  children,
}) {
  let layout = (
    <Stack style={root}>
      {top}
      <Stack
        direction={direction || "column"}
        justifyContent={justify || "space-evenly"}
        alignItems={align || "center"}
        style={content}
      >
        {children}
      </Stack>
      {bottom}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Stack>
  );
  layout = !full ? <Contained>{layout}</Contained> : layout;
  layout = textured ? <Textured>{layout}</Textured> : layout;
  layout = <MobileOnly>{layout}</MobileOnly>;
  return layout;
}

function Contained({ children }) {
  const { styles } = useTheme();
  return (
    <Container maxWidth="xs" style={{ height: "100%", background: styles.palette.background.default }}>
      {children}
    </Container>
  );
}

function MobileOnly({ children }) {
  return (
    <>
      <Hidden smUp>{children}</Hidden>
      <Hidden smDown>
        <Contained>
          <Stack style={root} justifyContent={"center"} alignItems="center">
            <Logo />
            <Box textAlign="center" mt={7}>
              <Image i="phone" width={100} />
              <Typography fontSize={25} mt={7}>
                <String s="mobile_only" />
              </Typography>
            </Box>
          </Stack>
        </Contained>
      </Hidden>
    </>
  );
}

function Textured({ children }) {
  const { styles } = useTheme();
  const background = styles.palette.background.default;
  const textureWrap = {
    background: "url(" + texture_svg + ")no-repeat",
    backgroundSize: "cover",
    padding: "2vh",
    height: "100%",
  };
  const innerWrap = {
    background,
    height: "100%",
  };
  return (
    <div style={textureWrap}>
      <div style={innerWrap}>{children}</div>
    </div>
  );
}

const root = { width: "100%", height: "100%" };
const content = { flexGrow: 1, overflowY: "auto", position: "relative" };
