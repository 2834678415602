import React from "react";
import { useTheme } from "../contexts/theme";
import {
  FormGroup,
  Stack,
  Typography,
  Switch,
  IconButton,
} from "@material-ui/core";
import DayIcon from "@material-ui/icons/WbSunny";
import NightIcon from "@material-ui/icons/Nightlight";
import String from "./String";
import palette from "../../config/themes/palette";

export default function ThemeSwitcher({invertColors}) {
  const { theme, toggleTheme } = useTheme();
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
        color={invertColors ? "textPrimary" : "textSecondary"}
        fontWeight={theme === "day" ? "bold" : "regular"}
        >
          <String s="day" />
        </Typography>
        <Switch
          icon={<SwitchIcon theme={theme} invertColors={invertColors} />}
          checkedIcon={<SwitchIcon theme={theme} invertColors={invertColors}/>}
          checked={theme === "night"}
          onChange={toggleTheme}
          color={invertColors ? "primary" : "secondary"}
        />
        <Typography
        color={invertColors ? "textPrimary" : "textSecondary"}
        fontWeight={theme === "night" ? "bold" : "regular"}
        >
          <String s="night" />
        </Typography>
      </Stack>
    </FormGroup>
  );
}

function SwitchIcon({ theme, invertColors }) {
  let background = theme === "day" ? palette.light_grey : palette.dark_grey;
  let dayColor = palette.dark_grey;
  let nightColor = palette.white;
  if (invertColors) {
    background = theme === "day" ? palette.dark_grey : palette.light_grey;
    dayColor = palette.white;
    nightColor = palette.dark_grey;
  }
  return (
    <IconButton
      size="small"
      style={{background}}
    >
      {theme === "day" ? (
        <DayIcon style={{ fontSize: "15px", color: dayColor  }} />
      ) : (
        <NightIcon style={{ fontSize: "15px", color: nightColor }} />
      )}
    </IconButton>
  );
}
