import palette from "./palette";
import common from "./common.js";

// day theme
const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    background: {
      default: palette.white,
      rgb: palette.white_rgb,
      contrast: palette.dark_grey,
    },
    secondary: {
      main: palette.white,
    },
    text: {
      primary: palette.dark_grey,
      secondary: palette.white,
    },
  }
};

const image_dir = "./day";

const day = { styles, image_dir };

export default day;
