const experience = {
  patterns: [
    {
      id: "pineta",
      label: "Pineta di Classe, percorso dantesco",
      replay: true,
      spots: [
        {
          id: "pineta-s01",
          label: "Capitolo 1",
          position: "pineta-p01",
          audio: "pineta-a01",
        },
        {
          id: "pineta-s02",
          label: "Capitolo 2",
          position: "pineta-p02",
          audio: "pineta-a02",
        },
        {
          id: "pineta-s03",
          label: "Capitolo 3",
          position: "pineta-p03",
          audio: "pineta-a03",
        },
        {
          id: "pineta-s04",
          label: "Capitolo 4",
          position: "pineta-p04",
          audio: "pineta-a04",
        },
      ]
    },

    {
      id: "verrucchio",
      label: "Verrucchio",
      spots: [
        {
          id: "verrucchio-s01",
          label: "Spalto e Torre dell'Orologio",
          position: "verrucchio-p01",
          audio: "verrucchio-a01",
        },
        {
          id: "verrucchio-s02",
          label: "Cortile interno",
          position: "verrucchio-p02",
          audio: "verrucchio-a02",
        },
        {
          // spot 3 uses same position as spot 2
          id: "verrucchio-s03",
          label: "Torrione",
          position: "verrucchio-p02",
          audio: "verrucchio-a03",
          after: "verrucchio-s02"
        },
        {
          id: "verrucchio-s04",
          label: "Piazza Malatesta",
          position: "verrucchio-p04",
          audio: "verrucchio-a04",
        },
        {
          id: "verrucchio-s05",
          label: "Collegiata",
          position: "verrucchio-p05",
          audio: "verrucchio-a05",
        },
        {
          id: "verrucchio-s06",
          label: "Mura medievali",
          position: "verrucchio-p06",
          audio: "verrucchio-a06",
        },
        {
          id: "verrucchio-s07",
          label: "Punto panoramico del Belvedere",
          position: "verrucchio-p07",
          audio: "verrucchio-a07",
        },
        {
          id: "verrucchio-s08",
          label: "Rocca del Passerello",
          position: "verrucchio-p08",
          audio: "verrucchio-a08",
        }
      ]
    },

    {
      id: "alberete",
      label: "Punte Alberete",
      replay: true,
      spots: [
        {
          id: "alberete-s01",
          label: "Carrarino al confine",
          position: "alberete-p01",
          audio: "alberete-a01",
        },
        {
          id: "alberete-s02",
          label: "Scagnarda dietro",
          position: "alberete-p02",
          audio: "alberete-a02",
        },
        {
          id: "alberete-s03",
          label: "Capanno Agnese",
          position: "alberete-p03",
          audio: "alberete-a03",
        },
        {
          id: "alberete-s04",
          label: "Osservatorio",
          position: "alberete-p04",
          audio: "alberete-a04",
        },
        {
          id: "alberete-s05",
          label: "Punto fotografico",
          position: "alberete-p05",
          audio: "alberete-a05",
        },
      ]
    },
  ],
  options: {
    visitedFilter: 5000
  }
};

export default experience;
