import React from "react";
import ReactDOM from "react-dom";
import App from "./ui";
import { toast } from "react-toastify";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import palette from "./config/themes/palette";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("fs-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => {
    toast.success('App installata correttamente', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: palette.green,
        color: palette.white
      }
    });
  },
  onUpdate: () => {
    toast.info('È disponibile una nuova versione. Chiudi e riapri l\'app per aggiornare', {
      position: "bottom-center",
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        backgroundColor: palette.green,
        color: palette.white
      }
    });
  }
});
