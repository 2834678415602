// pineta
import pineta1 from '../../assets/audio/it/Capitolo 1.mp3';
import pineta2 from '../../assets/audio/it/Capitolo 2.mp3';
import pineta3 from '../../assets/audio/it/Capitolo 3.mp3';
import pineta4 from '../../assets/audio/it/Capitolo 4.mp3';

// verrucchio
import verrucchio1 from '../../assets/audio/it/1.mp3';
import verrucchio2 from '../../assets/audio/it/2.mp3';
import verrucchio3 from '../../assets/audio/it/3.mp3';
import verrucchio4 from '../../assets/audio/it/4.mp3';
import verrucchio5 from '../../assets/audio/it/5.mp3';
import verrucchio6 from '../../assets/audio/it/6.mp3';
import verrucchio7 from '../../assets/audio/it/7.mp3';
import verrucchio8 from '../../assets/audio/it/8.mp3';

// alberete
import alberete1 from '../../assets/audio/it/punte alberete 1 P.mp3';
import alberete2 from '../../assets/audio/it/punte alberete 2 P.mp3';
import alberete3 from '../../assets/audio/it/punte alberete 3 P.mp3';
import alberete4 from '../../assets/audio/it/punte alberete 4 P.mp3';
import alberete5 from '../../assets/audio/it/punte alberete 5 P.mp3';

// cap 2 and 3 are inverted
const it = {

  // pineta
  pineta1: {
    url: pineta1,
    label: 'Capitolo 1',
    format: "mp3"
  },
  // capitoli 2 e 3 sono invertiti dopo collaudo
  pineta2: {
    url: pineta3,
    label: 'Capitolo 2',
    format: "mp3"
  },
  // capitoli 2 e 3 sono invertiti dopo collaudo
  pineta3: {
    url: pineta2,
    label: 'Capitolo 3',
    format: "mp3"
  },
  pineta4: {
    url: pineta4,
    label: 'Capitolo 4',
    format: "mp3"
  },

  // verrucchio
  verrucchio1: {
    url: verrucchio1,
    label: 'Spalto e Torre dell\'Orologio',
    format: "mp3"
  },
  verrucchio2: {
    url: verrucchio2,
    label: 'Cortile interno',
    format: "mp3"
  },
  verrucchio3: {
    url: verrucchio3,
    label: 'Torrione',
    format: "mp3"
  },
  verrucchio4: {
    url: verrucchio4,
    label: 'Piazza Malatesta',
    format: "mp3"
  },
  verrucchio5: {
    url: verrucchio5,
    label: 'Collegiata',
    format: "mp3"
  },
  verrucchio6: {
    url: verrucchio6,
    label: 'Mura medievali',
    format: "mp3"
  },
  verrucchio7: {
    url: verrucchio7,
    label: 'Punto panoramico del Belvedere',
    format: "mp3"
  },
  verrucchio8: {
    url: verrucchio8,
    label: 'Rocca del Passerello',
    format: "mp3"
  },

  // punte alberete
  alberete1: {
    url: alberete1,
    label: "Carrarino al confine",
    format: "mp3"
  },
  alberete2: {
    url: alberete2,
    label: "Scagnarda dietro",
    format: "mp3"
  },
  alberete3: {
    url: alberete3,
    label: "Capanno Agnese",
    format: "mp3"
  },
  alberete4: {
    url: alberete4,
    label: "Osservatorio",
    format: "mp3"
  },
  alberete5: {
    url: alberete5,
    label: "Punto fotografico",
    format: "mp3"
  },
};

export default it;