import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import GeoXp from "@mezzo-forte/geoxp";
import geo from "../../config/geo";
import audio from "../../config/audio";
import experience from "../../config/experience";
import { useLanguage } from "./language";

const ExperienceContext = React.createContext(null);

const config = { geo, audio: audio(), experience };

const downloadConfig = () => {
  const jsonConfig = JSON.stringify(config);
  const blob = new Blob([jsonConfig], { type: "text/json" });
  const link = document.createElement("a");
  link.download = 'pinetaDiClasse-geoXpConfig.json';
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");
  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  link.dispatchEvent(evt);
  link.remove();
};

window.downloadConfig = downloadConfig;

export default function Experience({ children }) {

  const geoXp = useMemo(() => {
    const instance = new GeoXp(config)
    window.geoXp = instance;
    console.log('GEOXP created')
    return instance;
  }, []);

  const simulateSpot = useCallback((spotId) => {
    let allSpots = [];
    experience.patterns.forEach(pattern => {
      allSpots = allSpots.concat(pattern.spots);
    });
    const spot = allSpots.find((el) => el.id === spotId);
    if (!spot) {
      console.warn("spot not found", spotId);
      return;
    }

    const posId = spot.position;

    const position = geo.positions.find((pos) => pos.id === posId);
    if (!position) {
      console.warn("position not found", posId);
    }

    console.info("simulating spot", spot, "with position", position);

    geoXp.updateGeolocation({
      coords: {
        latitude: position.lat,
        longitude: position.lon,
        accuracy: 10,
      },
    });
  }, [geoXp])

  window.simulateSpot = simulateSpot;

  const simulateAllTrip = () => {

    const BASE_TIMEOUT = 300000; // ms = 5 mins

    // giro principale
    const SPOTS = [
      'capitolo1',
      'capitolo2',
      'capitolo3',
      'capitolo4',
    ];

    SPOTS.forEach((spot, idx) => {
      setTimeout(() => {
        simulateSpot(spot);
      }, BASE_TIMEOUT * idx);
    });
  };

  window.simulateAllTrip = simulateAllTrip;

  const onInit = useOnInit();
  const { language } = useLanguage();

  const [position, setPosition] = useState();
  const [active, setActive] = useState();
  const [player, setPlayer] = useState();

  const reload = (_config) => _config ? geoXp.reload(_config) : geoXp.reload(config);
  const destroy = () => geoXp.destroy();
  const unlock = () => geoXp.unlock();
  const test = () => geoXp.audio.test();

  useEffect(() => {
    // listeners
    geoXp.on("position", (position) => {
      console.info('position event - position', position);
      setPosition(position);
    });
    geoXp.on("active", (spot) => {
      console.info('active event - spot', spot);
    });
    geoXp.on("outgoing", (spot) => {
      console.info('outgoing event - spot', spot);
    });
    geoXp.on("play", (audio) => {
      console.info('play event - audio', audio);
      setPlayer(audio);
      setActive(audio.spot);
    });
    geoXp.on("stop", (audio) => {
      console.info('stop event - audio', audio);
      if (geoXp.hasAudioPlaying()) {
        return;
      }
      setActive(undefined);
      setPlayer(undefined);
    });

    return () => geoXp.destroy();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!onInit) {
      const _config = { ...config, audio: audio(language) };
      reload(_config);
    }
  // eslint-disable-next-line
  }, [language, onInit]);

  const positions = geo.positions;

  return (
    <ExperienceContext.Provider
      value={{
        positions,
        position,
        active,
        player,
        reload,
        destroy,
        unlock,
        test,
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);

const useOnInit = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
