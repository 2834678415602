import { default_language } from "../languages";
import it from "./it";

const languages = { it };

export default function audio(language = default_language) {
  const selectedLangAudios = languages[language];
  return {
    sounds: [
      // pineta
      {
        id: "pineta-a01",
        label: selectedLangAudios.pineta1.label,
        url: selectedLangAudios.pineta1.url,
        format: selectedLangAudios.pineta1.format,
      },
      {
        id: "pineta-a02",
        label: selectedLangAudios.pineta2.label,
        url: selectedLangAudios.pineta2.url,
        format: selectedLangAudios.pineta2.format,
      },
      {
        id: "pineta-a03",
        label: selectedLangAudios.pineta3.label,
        url: selectedLangAudios.pineta3.url,
        format: selectedLangAudios.pineta3.format,
      },
      {
        id: "pineta-a04",
        label: selectedLangAudios.pineta4.label,
        url: selectedLangAudios.pineta4.url,
        format: selectedLangAudios.pineta4.format,
      },

      // verrucchio
      {
        id: "verrucchio-a01",
        label: selectedLangAudios.verrucchio1.label,
        url: selectedLangAudios.verrucchio1.url,
        format: selectedLangAudios.verrucchio1.format,
      },
      {
        id: "verrucchio-a02",
        label: selectedLangAudios.verrucchio2.label,
        url: selectedLangAudios.verrucchio2.url,
        format: selectedLangAudios.verrucchio2.format,
      },
      {
        id: "verrucchio-a03",
        label: selectedLangAudios.verrucchio3.label,
        url: selectedLangAudios.verrucchio3.url,
        format: selectedLangAudios.verrucchio3.format,
      },
      {
        id: "verrucchio-a04",
        label: selectedLangAudios.verrucchio4.label,
        url: selectedLangAudios.verrucchio4.url,
        format: selectedLangAudios.verrucchio4.format,
      },
      {
        id: "verrucchio-a05",
        label: selectedLangAudios.verrucchio5.label,
        url: selectedLangAudios.verrucchio5.url,
        format: selectedLangAudios.verrucchio5.format,
      },
      {
        id: "verrucchio-a06",
        label: selectedLangAudios.verrucchio6.label,
        url: selectedLangAudios.verrucchio6.url,
        format: selectedLangAudios.verrucchio6.format,
      },
      {
        id: "verrucchio-a07",
        label: selectedLangAudios.verrucchio7.label,
        url: selectedLangAudios.verrucchio7.url,
        format: selectedLangAudios.verrucchio7.format,
      },
      {
        id: "verrucchio-a08",
        label: selectedLangAudios.verrucchio8.label,
        url: selectedLangAudios.verrucchio8.url,
        format: selectedLangAudios.verrucchio8.format,
      },

      // punte alberete
      {
        id: "alberete-a01",
        label: selectedLangAudios.alberete1.label,
        url: selectedLangAudios.alberete1.url,
        format: selectedLangAudios.alberete1.format,
      },
      {
        id: "alberete-a02",
        label: selectedLangAudios.alberete2.label,
        url: selectedLangAudios.alberete2.url,
        format: selectedLangAudios.alberete2.format,
      },
      {
        id: "alberete-a03",
        label: selectedLangAudios.alberete3.label,
        url: selectedLangAudios.alberete3.url,
        format: selectedLangAudios.alberete3.format,
      },
      {
        id: "alberete-a04",
        label: selectedLangAudios.alberete4.label,
        url: selectedLangAudios.alberete4.url,
        format: selectedLangAudios.alberete4.format,
      },
      {
        id: "alberete-a05",
        label: selectedLangAudios.alberete5.label,
        url: selectedLangAudios.alberete5.url,
        format: selectedLangAudios.alberete5.format,
      },
    ],
  };
}
