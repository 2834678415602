const geo = {
  positions: [
    
    // pineta
    {
      id: "pineta-p01",
      label: "Capitolo 1",
      lon: 12.271721,
      lat: 44.354003,
      radius: 90,
      deadband: 300
    },
    {
      id: "pineta-p02",
      label: "Capitolo 2",
      lon: 12.275915,
      lat: 44.348504,
      radius: 90,
      deadband: 300
    },
    {
      id: "pineta-p03",
      label: "Capitolo 3",
      lon: 12.280366,
      lat: 44.348236,
      radius: 90,
      deadband: 400
    },
    {
      id: "pineta-p04",
      label: "Capitolo 4",
      lon: 12.275090,
      lat: 44.355591,
      radius: 90,
      deadband: 400
    },

    // verrucchio
    // deadbands are ~ infinite to allow for complete spot fruition
    {
      id: "verrucchio-p01",
      label: "Spalto e torre",
      lon: 12.421514,
      lat: 43.984095,
      radius: 10,
      deadband: 500
    },
    {
      id: "verrucchio-p02",
      label: "Cortile interno",
      lon: 12.421424,
      lat: 43.984347,
      radius: 10,
      deadband: 500
    },
    // p3 is unused, spot 3 uses p02
    // {
    //   id: "verrucchio-p03",
    //   label: "Torrione",
    //   lon: 12.421155,
    //   lat: 43.984358,
    //   radius: 10,
    //   deadband: 500
    // },
    {
      id: "verrucchio-p04",
      label: "Piazza Malatesta",
      lon: 12.421483,
      lat: 43.983474,
      radius: 20,
      deadband: 500
    },
    {
      id: "verrucchio-p05",
      label: "Collegiata",
      lon: 12.421420,
      lat: 43.982842,
      radius: 20,
      deadband: 500
    },
    {
      id: "verrucchio-p06",
      label: "Mura medievali",
      lon: 12.422161,
      lat: 43.982216,
      radius: 20,
      deadband: 500
    },
    {
      id: "verrucchio-p07",
      label: "Belvedere",
      lon: 12.422752,
      lat: 43.983035,
      radius: 20,
      deadband: 500
    },
    {
      id: "verrucchio-p08",
      label: "Rocca del Passerello",
      lon: 12.422573,
      lat: 43.981928,
      radius: 20,
      deadband: 500
    },

    // punte alberete
    {
      id: "alberete-p01",
      label: "Carrarino al confine",
      lon: 12.213336,
      lat: 44.512285,
      radius: 70,
      deadband: 210
    },
    {
      id: "alberete-p02",
      label: "Scagnarda dietro",
      lon: 12.215590,
      lat: 44.514901,
      radius: 70,
      deadband: 150
    },
    {
      id: "alberete-p03",
      label: "Capanno Agnese",
      lon: 12.217417,
      lat: 44.516861,
      radius: 50,
      deadband: 210
    },
    {
      id: "alberete-p04",
      label: "Osservatorio",
      lon: 12.220944,
      lat: 44.515917,
      radius: 50,
      deadband: 60
    },
    {
      id: "alberete-p05",
      label: "Punto fotografico",
      lon: 12.222389,
      lat: 44.515472,
      radius: 50,
      deadband: 200
    },
  ],
  options: {
    enableHighAccuracy: true,
    accuracy: 20,
    defaultDeadband: 100,
    defaultRadius: 80,
    defaultFetch: 1,
  }
};

export default geo;
