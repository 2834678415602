import packageJson from "../../../package.json";

const VERSION = packageJson.version;

const divStyle = {
  position: "fixed",
  top: 0,
  right: 0,
  margin: "calc(2vh + 10px)",
  opacity: 0.6,
};

export default function AppVersion() {
  return (
    <div style={divStyle}>
      <small>
        <em>v{VERSION}</em>
      </small>
    </div>
  );
}
