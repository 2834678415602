import React, { useState } from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import themes from "../../config/themes";

const ThemeContext = React.createContext(null);

export default function Theme({ children }) {
  const [theme, setTheme] = useState("day");
  const toggleTheme = () => setTheme(theme === "day" ? "night" : "day");
  let { styles, image_dir } = themes[theme];
  styles = createMuiTheme(styles);
  return (
    <ThemeContext.Provider value={{ theme, styles, image_dir, toggleTheme }}>
      <ThemeProvider theme={styles}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => React.useContext(ThemeContext);
