import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import PreviousIcon from "@material-ui/icons/FastRewind";
import NextIcon from "@material-ui/icons/FastForward";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import { useExperience } from "../contexts/experience";

export default function Player({ spot }) {
  const [progress, setProgress] = useState(0);
  const { player } = useExperience();

  const audio = player?.audio;
  const duration = audio?._duration;
  const perc = (progress / duration) * 100;
  const elapsed = formatTimer(progress);
  // const countdown = formatTimer(duration - progress);

  useEffect(() => {
    const seek = setInterval(() => {
      try {
        const _p = audio?.seek();
        setProgress(_p);
      } catch {}
    }, 100);
    return () => clearInterval(seek);
  }, [audio]);

  const skip = (s) => {
    const _s = progress + s;
    if (_s > 0 && _s < duration) audio?.seek(_s);
  };

  return (
    <Card style={{ textAlign: "center", borderRadius: 0 }}>
      <Box mt={3} mb={2}>
        <Typography color="textSecondary" variant="h5" mt={1}>
          {spot.label}
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid
          container
          justify="space-around"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <IconButton color="secondary" onClick={() => skip(-10)}>
              <PreviousIcon style={{ fontSize: "10vw" }} />
            </IconButton>
            <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              10 s
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => (audio?.playing() ? audio.pause() : audio.play())}
              style={btnStyle} color="secondary"
            >
              <Box style={countDownStyle}>
                {audio.playing() ? <PauseIcon style={{ fontSize: "10vw" }} /> : <PlayIcon style={{ fontSize: "10vw" }} />}
              </Box>
              <CircularProgress
                variant="determinate"
                value={Math.floor(perc)}
                style={progressStyle}
                thickness={2}
                color="secondary"
              />
            </IconButton>
            <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              {elapsed.mm}:{elapsed.ss}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={() => skip(10)}>
              <NextIcon style={{ fontSize: "10vw" }} />
            </IconButton>
            <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              10 s
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

const formatTimer = (t) => {
  if (t > 0) {
    const o = new Date(0);
    const p = new Date(t * 1000);
    const array = new Date(p.getTime() - o.getTime())
      .toISOString()
      .split('T')[1]
      .split('Z')[0]
      .split(':');
    return {
      mm: array[1],
      ss: array[2].split('.')[0]
    };
  } else {
    return { mm: "00", ss: "00" };
  }
};

const btnStyle = {
  position: "relative",
  border: "2px solid",
  height: "22vw",
  width: "22vw",
};
const countDownStyle = {
  position: "absolute",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
};
const progressStyle = { position: "absolute", height: "107%", width: "107%" };
