const palette = {
  white: "#FFFFFF",
  white_rgb: "255, 255, 255",
  green: "#007D39",
  pink: "#01ACE0",
  grey: "#A5A093",
  light_grey: "#F2EFEB",
  light_grey_rgb: "242, 239, 235",
  dark_grey: "#191A1C",
  dark_grey_rgb: "25, 26, 28"
};

export default palette;
