import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Backdrop,
} from "@material-ui/core";
import NoSleep from "nosleep.js";
import Layout from "../components/Layout";
import String from "../components/String";
import ThemeSwitcher from "../components/ThemeSwitcher";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";
import { useLanguage } from "../contexts/language";
import Experience, { useExperience } from "../contexts/experience";
import useVisibility from "../hooks/useVisibility";

const noSleep = new NoSleep();

export default function Tour() {

  const { alarmSound } = useLanguage();
  const isVisible = useVisibility();
  const [start, setStart] = useState(false);

  useEffect(() => {
    // prevent pull-down-to-refresh
    document.body.classList.add('no-pull-refresh');

    return () => {
      document.body.classList.remove('no-pull-refresh');
      noSleep.disable();
    }
  }, []);

  return (
    <Experience>
      <Layout
        full
        top={<Header />}
        bottom={
          <Footer>
            <ThemeSwitcher />
          </Footer>
        }
      >
        <Overlay
          start={start}
          setStart={setStart}
        />
        <Map />
        {/* <Lock /> */}
        {start && !isVisible && (
          <audio src={alarmSound} autoPlay loop style={{ display: "none" }} />
        )}
      </Layout>
    </Experience>
  );
}

function Overlay({ start, setStart }) {

  const { unlock } = useExperience();

  return (
    <Backdrop style={{ position: "absolute", zIndex: 9 }} open={(!start)}>
      <Layout justify="center">
        <Box textAlign="center" ml={4} mr={4}>
          {
            !start &&
            <>
              <Typography variant="title" mb={2}>
                <String s="areyouready" />
              </Typography>
              <Typography variant="text" mb={3}>
                <String s="beforestart" />
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  noSleep.enable();
                  unlock();
                  setStart(true);
                }}
              >
                <String s="start" />
              </Button>
            </>
          }
        </Box>
      </Layout>
    </Backdrop>
  );
}
